var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addOrRevise" },
    [
      _c("Title", { attrs: { name: "项目基本信息" } }),
      _c(
        "div",
        { staticStyle: { padding: "20px" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                model: _vm.formData,
                "label-position": "right",
                "label-width": "130px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "项目名称", prop: "projName" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入项目名称" },
                    model: {
                      value: _vm.formData.projName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projName", $$v)
                      },
                      expression: "formData.projName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "项目所在地", prop: "projAddress" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入项目所在地" },
                    model: {
                      value: _vm.formData.projAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projAddress", $$v)
                      },
                      expression: "formData.projAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "开发商", prop: "developer" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入开发商" },
                    model: {
                      value: _vm.formData.developer,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "developer", $$v)
                      },
                      expression: "formData.developer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "承建商", prop: "constractor" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入承建商" },
                    model: {
                      value: _vm.formData.constractor,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "constractor", $$v)
                      },
                      expression: "formData.constractor",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "合同签约单位", prop: "contractEntp" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入合同签约单位" },
                    model: {
                      value: _vm.formData.contractEntp,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contractEntp", $$v)
                      },
                      expression: "formData.contractEntp",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "合同编号", prop: "contractNo" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入合同编号" },
                    model: {
                      value: _vm.formData.contractNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contractNo", $$v)
                      },
                      expression: "formData.contractNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "销售模式", prop: "sellMode" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "280px" },
                      attrs: { placeholder: "请选择销售模式" },
                      model: {
                        value: _vm.formData.sellMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "sellMode", $$v)
                        },
                        expression: "formData.sellMode",
                      },
                    },
                    _vm._l(_vm.sell_mode, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "铝膜栋数", prop: "lvDs" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { type: "number", placeholder: "请输入铝膜栋数" },
                    model: {
                      value: _vm.formData.lvDs,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lvDs", $$v)
                      },
                      expression: "formData.lvDs",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "单层面积", prop: "areaEc" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { type: "number", placeholder: "请输入单层面积" },
                    model: {
                      value: _vm.formData.areaEc,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "areaEc", $$v)
                      },
                      expression: "formData.areaEc",
                    },
                  }),
                  _c("div", { staticClass: "tags" }, [_vm._v("平分米 / m²")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "层数", prop: "lvCs" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { type: "number", placeholder: "请输入层数" },
                    model: {
                      value: _vm.formData.lvCs,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lvCs", $$v)
                      },
                      expression: "formData.lvCs",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "项目类别", prop: "projType" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "280px" },
                      attrs: { placeholder: "请选择项目类别" },
                      model: {
                        value: _vm.formData.projType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "projType", $$v)
                        },
                        expression: "formData.projType",
                      },
                    },
                    _vm._l(_vm.projectType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "业务负责人", prop: "projLeader" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { placeholder: "请输入业务负责人" },
                    model: {
                      value: _vm.formData.projLeader,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projLeader", $$v)
                      },
                      expression: "formData.projLeader",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "铝模板综合单价", prop: "lvPrices" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: {
                      type: "number",
                      placeholder: "请输入铝模板综合单价",
                    },
                    model: {
                      value: _vm.formData.lvPrices,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "lvPrices", $$v)
                      },
                      expression: "formData.lvPrices",
                    },
                  }),
                  _c("div", { staticClass: "tags" }, [_vm._v("元/方")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "合同金额", prop: "contractAmt" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "280px" },
                    attrs: { type: "number", placeholder: "请输入合同金额" },
                    model: {
                      value: _vm.formData.contractAmt,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "contractAmt", $$v)
                      },
                      expression: "formData.contractAmt",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "租期", prop: "rentTerm" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "180px" },
                    attrs: { type: "number", placeholder: "请输入租期" },
                    model: {
                      value: _vm.formData.rentTerm,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "rentTerm", $$v)
                      },
                      expression: "formData.rentTerm",
                    },
                  }),
                  _c("div", { staticClass: "tags" }, [_vm._v("月")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "合同进场时间", prop: "addTime" },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "280px" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.formData.addTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "addTime", $$v)
                      },
                      expression: "formData.addTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "48%" },
                  attrs: { label: "上传合同文件", required: true },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        action: _vm.url,
                        accept: ".PDF",
                        "on-preview": _vm.handlePreview,
                        "on-remove": _vm.handleRemove,
                        "on-success": _vm.uploadSuccess,
                        "before-remove": _vm.beforeRemove,
                        limit: 5,
                        "on-exceed": _vm.handleExceed,
                        "file-list": _vm.fileList,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("选择文件")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v("温馨提示：上传格式为PDF格式")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center", margin: "20px" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v(" 保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }