<template>
    <div class="addOrRevise">
        <Title name='项目基本信息'></Title>

        <div style="padding:20px">
            <el-form ref='form' :inline="true" :model="formData" label-position="right" label-width="130px"
                :rules="rules">
                <el-form-item label="项目名称" prop="projName" style="width:48%">
                    <el-input v-model="formData.projName" placeholder="请输入项目名称" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="项目所在地" prop="projAddress" style="width:48%">
                    <el-input v-model="formData.projAddress" placeholder="请输入项目所在地" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="开发商" prop="developer" style="width:48%">
                    <el-input v-model="formData.developer" placeholder="请输入开发商" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="承建商" prop="constractor" style="width:48%">
                    <el-input v-model="formData.constractor" placeholder="请输入承建商" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="合同签约单位" prop="contractEntp" style="width:48%">
                    <el-input v-model="formData.contractEntp" placeholder="请输入合同签约单位" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="合同编号" prop="contractNo" style="width:48%">
                    <el-input v-model="formData.contractNo" placeholder="请输入合同编号" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="销售模式" prop="sellMode" style="width:48%">
                    <el-select v-model="formData.sellMode" placeholder="请选择销售模式" style="width:280px">
                        <el-option v-for="item in sell_mode" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="铝膜栋数" prop="lvDs" style="width:48%">
                    <el-input type="number" v-model="formData.lvDs" placeholder="请输入铝膜栋数" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="单层面积" prop="areaEc" style="width:48%">
                    <el-input type="number" v-model="formData.areaEc" placeholder="请输入单层面积" style="width:180px"></el-input>
                    <div class="tags">平分米 / m²</div>
                </el-form-item>
                <el-form-item label="层数" prop="lvCs" style="width:48%">
                    <el-input type="number" v-model="formData.lvCs" placeholder="请输入层数" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="项目类别" prop="projType" style="width:48%">
                    <el-select v-model="formData.projType" placeholder="请选择项目类别" style="width:280px">
                        <el-option v-for="item in projectType" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="业务负责人" prop="projLeader" style="width:48%">
                    <el-input v-model="formData.projLeader" placeholder="请输入业务负责人" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="铝模板综合单价" prop="lvPrices" style="width:48%">
                    <el-input type="number" v-model="formData.lvPrices" placeholder="请输入铝模板综合单价" style="width:180px"></el-input>
                    <div class="tags">元/方</div>
                </el-form-item>
                <el-form-item label="合同金额" prop="contractAmt" style="width:48%">
                    <el-input type="number" v-model="formData.contractAmt" placeholder="请输入合同金额" style="width:280px"></el-input>
                </el-form-item>
                <el-form-item label="租期" prop="rentTerm" style="width:48%">
                    <el-input type="number" v-model="formData.rentTerm" placeholder="请输入租期" style="width:180px"></el-input>
                    <div class="tags">月</div>
                </el-form-item>
                <el-form-item label="合同进场时间" prop="addTime" style="width:48%">
                    <el-date-picker v-model="formData.addTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
                        style="width:280px">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="上传合同文件" style="width:48%" :required='true'>
                    <el-upload class="upload-demo" :action="url" accept='.PDF' :on-preview="handlePreview" 
                        :on-remove="handleRemove" :on-success='uploadSuccess' :before-remove="beforeRemove" 
                        :limit="5" :on-exceed="handleExceed" :file-list="fileList">
                        <el-button size="small" type="primary">选择文件</el-button>
                        <div slot="tip" class="el-upload__tip">温馨提示：上传格式为PDF格式</div>
                    </el-upload>
                </el-form-item>
            </el-form>
        </div>

        <div style="text-align:center;margin:20px">
            <el-button type="primary" @click="submitForm"> 保存</el-button>
        </div>
    </div>
</template>

<script>
    import Title from '../../../component/Title'
    import {
        AddEquipment,
        uploadPath
    } from '../../../api'
    export default {
        data() {
            return {
                formData: {
                    projName: '',
                    projAddress: '',
                    developer: '',
                    constractor: '',
                    contractEntp: '',
                    contractNo: '',
                    sellMode: '',
                    lvDs: '',
                    areaEc: '',
                    lvCs: '',
                    projType: '',
                    projLeader: '',
                    lvPrices: '',
                    contractAmt: '',
                    rentTerm: '',
                    addTime: '',
                },
                // formData: {
                //     projName: '测试上传',
                //     projAddress: '测试上传',
                //     developer: '测试上传',
                //     constractor: '测试上传',
                //     contractEntp: '测试上传',
                //     contractNo: '12',
                //     sellMode: 'SELL',
                //     lvDs: '12',
                //     areaEc: '12',
                //     lvCs: '12',
                //     projType: 'ZZ',
                //     projLeader: '测试上传',
                //     lvPrices: '12',
                //     contractAmt: '12',
                //     rentTerm: '12',
                //     addTime: '2021-11-20',
                // },

                rules: {
                    projName: [{
                        required: true,
                        message: '请输入项目名称',
                        trigger: 'blur'
                    }],
                    projAddress: [{
                        required: true,
                        message: '请输入项目所在地',
                        trigger: 'blur'
                    }],
                    developer: [{
                        required: true,
                        message: '请输入开发商',
                        trigger: 'blur'
                    }],
                    constractor: [{
                        required: true,
                        message: '请输入承建商',
                        trigger: 'blur'
                    }],
                    contractEntp: [{
                        required: true,
                        message: '请输入合同签约单位',
                        trigger: 'blur'
                    }],
                    contractNo: [{
                        required: true,
                        message: '请输入合同编号',
                        trigger: 'blur'
                    }],
                    sellMode: [{
                        required: true,
                        message: '请选择销售模式',
                        trigger: 'blur'
                    }],
                    lvDs: [{
                        required: true,
                        message: '请输入铝膜栋数',
                        trigger: 'blur'
                    }],
                    areaEc: [{
                        required: true,
                        message: '请输入单层面积',
                        trigger: 'blur'
                    }],
                    lvCs: [{
                        required: true,
                        message: '请输入层数',
                        trigger: 'blur',

                    }],
                    projType: [{
                        required: true,
                        message: '请选择项目类别',
                        trigger: 'blur'
                    }],
                    projLeader: [{
                        required: true,
                        message: '请输入业务负责人',
                        trigger: 'blur'
                    }],
                    lvPrices: [{
                        required: true,
                        message: '请输入铝模板综合单价',
                        trigger: 'blur'
                    }],
                    contractAmt: [{
                        required: true,
                        message: '请输入合同金额',
                        trigger: 'blur'
                    }],
                    rentTerm: [{
                        required: true,
                        message: '请输入租期',
                        trigger: 'blur'
                    }],
                    addTime: [{
                        required: true,
                        message: '请选择合同进场时间',
                        trigger: 'blur'
                    }],
                },
                sell_mode: [{
                    value: 'SELL',
                    label: '销售'
                }, {
                    value: 'RENT',
                    label: '出租'
                }],
                projectType: [{
                    value: 'ZZ',
                    label: '住宅'
                }, {
                    value: 'SY',
                    label: '商用'
                }, {
                    value: 'XZL',
                    label: '写字楼'
                }, {
                    value: 'ORTHER',
                    label: '其他'
                }],

                url: uploadPath,
                fileList: [],
            }
        },
        methods: {
            handleRemove(file, fileList) {
                this.fileList = fileList
                // console.log(file, fileList);
            },

            // 点击下载
            handlePreview(file) {
                window.open(file.url)
            },

            handleExceed(files, fileList) {
                this.$message.warning(
                    `最多可选择 5 个文件上传`);
            },

            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },

            submitForm() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if (!this.fileList.length) return this.$message({
                            message: '请选择上传合同文件后，再提交保存'
                        })
                        var formData = this.formData
                        var fileList = this.fileList
                        
                        formData.contractFile = fileList[0].url
                        formData.contractFileName = fileList[0].name

                        for (var i = 1; i < fileList.length; i++) {
                            formData.contractFile = formData.contractFile + ',' + fileList[i].url
                            formData.contractFileName = formData.contractFileName + ',' + fileList[i].name
                        }

                        AddEquipment(formData).then(e => {
                            if (e.errno == 0) {
                                this.$message({
                                    message: '添加成功',
                                    type: 'success'
                                });

                                this.$router.push({
                                    path: '/User/device?customer=buyder'
                                })
                                return
                            }
                            this.$message({
                                message: e.errmsg,
                                type: 'error'
                            });
                        })
                    } else {
                        return false;
                    }
                });
            },

            uploadSuccess(response, file, fileList) {
                // debugger
                if (response.errno == 0) {
                    this.fileList = fileList
                    this.fileList[fileList.length - 1].url = response.data.url
                    return
                }
                console.log('上传结果', response)
            }
        },
        components: {
            Title
        }
    }
</script>

<style lang="less" scoped>
    .addOrRevise {
        background: #fff;
        padding-bottom: 20px;
    }

    .tags {
        display: inline-block;
        width: 90px;
        margin-left: 10px;
        padding-left: 10px;
        border: 1px solid #C0C4CC;
        box-sizing: border-box;
        height: 40px;
    }

    /deep/.el-form-item {
        margin-bottom: 30px;
    }

        // 去掉数字输入框右边的上下箭头
    /deep/input::-webkit-outer-spin-button,
    /deep/input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /deep/input[type="number"] {
        -moz-appearance: textfield;
    }
</style>